<template lang="pug">
  v-card
    .qr-code-modal
      v-card-title
        h2.card-title {{ name }}
      v-card-content
        img.qr-code-modal__img(
          :src="img")
      v-card-actions
        .qr-code-modal__actions-wrapper
          button.btn.btn--brand(
            @click="onDownload") {{ 'actions.download' | translate }}

</template>

<script>
export default {
  name: 'QrCodeModal',

  props: {
    name: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    }
  },

  methods: {
    wrapText (ctx, text, x, y, maxWidth, lineHeight) {
      const words = text.split(' ')
      let line = ''

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, y)
          line = words[n] + ' '
          y += lineHeight
        } else {
          line = testLine
        }
      }
      ctx.fillText(line, x, y)
    },

    onDownload () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = 350
      canvas.height = 430

      const maxWidth = 300
      const lineHeight = 25
      const x = 25
      const y = 360
      const text = this.name

      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, 350, 430)

      const img = new Image()
      img.crossOrigin = 'anonymous'

      img.onload = () => {
        ctx.drawImage(img, 25, 25, 300, 300)
        ctx.font = '20px Museo Sans'
        ctx.fillStyle = '#000'
        this.wrapText(ctx, text, x, y, maxWidth, lineHeight)

        const img2 = canvas.toDataURL('image/png')
        const link = document.createElement('a')
        link.download = 'Download.jpg'
        link.href = img2
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      img.src = `https://cors-anywhere.herokuapp.com/${this.img}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .qr-code-modal {
    &__img {
      width: 100%;
      height: auto;
    }

    &__actions-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
</style>
